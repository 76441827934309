import Vue from 'vue'
import VueRouter from 'vue-router'
import ArtAssetDetail from "@/views/ArtAssetDetail";
import Contact from "@/views/Contact";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/contact/:subject?',
    name: 'contact',
    component: Contact,
    props: true,
  },
  {
    path: '/artAssetDetail',
    name: 'artAssetDetail',
    component: ArtAssetDetail,
    props: route => ({ query: route.query.q }),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
