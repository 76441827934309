<template>
  <section class="blogpost-container main-content paddsection">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-md-offset-2">
          <div class="row">
            <div class="container-main single-main">
              <div class="col-md-12">
                <div class="block-main mb-30">
                  <img :src="blok.image.filename" class="img-responsive blog-image" alt="">
                  <div class="content-main single-post padDiv">
                    <div class="journal-txt">
                      <h1 class="headline">{{blok.title}}</h1>
                    </div>
                    <div :v-editable="blok">
                      <component :key="blok._uid" v-for="blok in blok.content" :blok="blok" :is="blok.component | dashify"></component>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Blogpost",
  props: ["blok"],
  mounted() {
    console.log("visiting blogpost")
  }
}
</script>

<style scoped>
.blog-image {
  width: 100%;
}

.headline {
  margin-bottom: 1em;
}
</style>
