<template>
  <section id="portfolio-details" class="portfolio-details" v-if="asset">
    <div class="container">

      <div class="row gy-4">

        <div class="col-lg-8">
          <img class="asset-image" :src="asset.asset.filename" alt="">
        </div>

        <div class="col-lg-4">
          <div class="portfolio-info">
            <h3>Project information</h3>
            <ul>
              <li><strong>Dimensions</strong>: {{asset.dimensions}}</li>
              <li v-if="asset.creationDate"><strong>Creation date</strong>: {{asset.creationDate}}</li>
              <li v-if="asset.url"><strong>Project URL</strong>: <a href="#">{{asset.url.url}}</a></li>
              <li><strong>Labels</strong>: {{asset.labels}}</li>
            </ul>
          </div>
          <div class="portfolio-description">
            <h2>{{asset.title}}</h2>
            <p>
              {{asset.description}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ArtAssetDetail",
  mounted() {
    this.assetId = this.$route.query.id;
    this.asset = this.findArtAsset(this.assetId);
  },
  computed: {
  },
  data: function() {
    return {
      assetId: null,
      asset: null,
    };
  },
  methods: {
    findArtAsset: function (id) {
      const dataString = JSON.stringify(this.$store.state.storyblokData);
      const index = dataString.indexOf(id);
      if(index < 0) {
        this.$router.push("/404");
      }

      const start = this.findObjectBeginning(dataString, index);
      const end = this.findObjectEnd(dataString, index);
      console.log("start: " + start + "; end: " + end);

      const artAssetStr = dataString.substr(start, end-start);
      console.log(artAssetStr);

      if(start === -1 || end === -1) {
        console.error("invalid artAsset string: " + artAssetStr);
        return null;
      }

      return JSON.parse(artAssetStr);
    },
    findObjectBeginning: function(str, startPos) {
      let counter = 0;
      for(let i = startPos; i >= 0; i--) {
        if(str[i] === "{") {
          counter--;
          if(counter < 0) {
            return i;
          }
        }
        if(str[i] === "}") {
          counter++;
        }
      }
      return -1;
    },
    findObjectEnd: function(str, startPos) {
      let counter = 0;
      for(let i = startPos; i < str.length; i++) {
        if(str[i] === "}") {
          counter--;
          if(counter < 0) {
            return i + 1;
          }
        }
        if(str[i] === "{") {
          counter++;
        }
      }
      return -1;
    }
  }
}
</script>

<style scoped>
#portfolio-details {
  margin-top: 5em;
}

.asset-image {
  width:100%;
  margin-bottom: 2em;
}
</style>
