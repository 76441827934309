<template>
  <div class="container d-flex align-items-center justify-content-between">
    <a href="#" class="logo">
      <img src="logo.png" alt="logo" class="img-fluid" />
    </a>
    <nav id="navbar" class="navbar" :class="{ 'navbar-mobile': mobileToggled }">
      <ul>
        <li v-for="story in menuItems" :key="story.id" @click="mobileToggled = false">
          <router-link :to="path(story)" class="nav-link">{{ story.name }}</router-link>
        </li>
        <li @click="mobileToggled = false">
          <a class="nav-link" href="https://ko-fi.com/bauxxi/shop" target="_blank">
            <span>Shop</span>
            <i class="bi bi-cart"></i>
          </a>
        </li>
      </ul>
      <i class="bi mobile-nav-toggle" :class="{ 'bi-list': !mobileToggled, 'bi-x': mobileToggled }"
        @click="toggleMobileNav"></i>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Menu.vue",
  methods: {
    path(story) {
      return "/" + story.slug;
    },
    toggleMobileNav: function () {
      this.mobileToggled = !this.mobileToggled;
    }
  },
  data: function () {
    return {
      mobileToggled: false
    };
  },
  computed: {
    menuItems: function () {
      const result = [];
      this.$store.state.storyblokData.stories.forEach(story => {
        if (story.parent_id === 0 && !story.content.ignoreInNavigation) {
          result.push(story);
        }
      });
      return result;
    }
  },
}
</script>

<style>
.mobile-nav-toggle {
  margin-left: 1em;
  margin-top: 0.3em;
}
</style>
