<template>
  <div id="footer" class="text-center">
    <div class="container">
      <div class="socials-media text-center">
        <ul class="list-unstyled">
          <li>
            <a href="https://www.facebook.com/bauxxisart" target="_blank"
              ><i class="bi bi-facebook"></i
            ></a>
          </li>
          <li>
            <a href="https://www.twitter.com/bauxi1990" target="_blank"
              ><i class="bi bi-twitter"></i
            ></a>
          </li>
          <li>
            <a href="https://www.instagram.com/bauxxis_art" target="_blank"
              ><i class="bi bi-instagram"></i
            ></a>
          </li>
          <li>
            <a href="https://www.twitch.tv/bauxxistreams" target="_blank"
              ><i class="bi bi-twitch"></i
            ></a>
          </li>
          <li>
            <a href="https://www.patreon.com/bauxxi" target="_blank"
              ><i class="bi bi-file-ppt"></i
            ></a>
          </li>
        </ul>

        <p>
          <a id="impressum" href="#" @click="goToImpressum">Impressum</a>
        </p>

        <p>Copyright 2021 Bauxxi, all rights reserved</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    goToImpressum: function () {
      this.$router.push("/impressum");
    },
  },
};
</script>

<style scoped>
#footer {
  border-top: 1px solid gray;
}
#impressum {
  color: lightgray;
}
</style>
