<template>
  <section class="commission-grid container">
    <div class="row">
      <CommissionColumn class="col-md my-2 column" :blok="column" v-for="column in blok.Grid" :key="JSON.stringify(column)">
      </CommissionColumn>
    </div>
  </section>
</template>

<script>
import CommissionColumn from "@/components/CommissionColumn";

export default {
  name: "CommissionGrid",
  components: {CommissionColumn},
  props: ["blok"],
}
</script>

<style scoped>
  .commission-grid {
    margin-top: 7em;
    margin-bottom: 3em;
  }

  .column {
    overflow: hidden;
    padding-top: 2em;
    padding-bottom: 2em;
  }
</style>
