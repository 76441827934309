<template>
  <div :v-editable="blok" v-masonry transition-duration="0.3s" item-selector=".item">
    <component :key="blok._uid" v-for="blok in blok.columns" :blok="blok" :is="blok.component | dashify"></component>
  </div>
</template>

<script>
export default {
  props: ['blok'],
  name: "Grid"
}
</script>
