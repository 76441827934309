<template>
  <div :v-editable="blok" id="about" class="paddsection">
    <div class="container">
      <div class="row justify-content-between">

        <div class="col-lg-4 ">
          <div class="div-img-bg">
            <div class="about-img">
              <img :src="blok.image.filename" class="img-responsive" alt="me">
            </div>
          </div>
        </div>

        <div class="col-lg-7">
          <div class="about-descr">

            <p class="p-heading">{{blok.description}}</p>
            <p class="separator">{{blok.subtext}}</p>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  props: ["blok"]
}
</script>

<style scoped>

</style>
