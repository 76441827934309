<template>
  <div id="app" v-on:scroll="handleScroll">
    <header id="header" class="fixed-top" v-bind:class="{'header-scrolled': isHeaderScrolled || !isHome}" v-if="$store.state.storyblokData">
      <Menu/>
    </header>
    <main v-if="$store.state.storyblokData" id="main">
      <router-view/>
    </main>
    <footer>
      <custom-footer></custom-footer>
    </footer>
    <div v-if="!$store.state.storyblokData">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import Menu from './components/Menu';
import '@/assets/js/folio.js';
import storyblok from "@/utils/storyblok";
import router from "@/router";
import Page from "@/components/Page";
import Blogpost from "@/components/Blogpost";

export default {
  components: { Menu },
  methods: {
    updateRouter(stories) {
      const toComponent = (com) => {
        switch (com) {
          case "blogpost":
            return Blogpost;
          case "page":
          default:
            return Page;
        }
      };

      stories.forEach(story => {
        router.addRoute({
          name: story.name,
          path: "/" + story.full_slug,
          component: toComponent(story.content.component),
          props: { blok: story.content }
        })
      });
    },
    handleScroll: function() {
      this.isHeaderScrolled = window.scrollY > 0;
    }
  },
  computed: {
    displayMenu: function() {
      return this.$store.state.storyblokData && this.isHeaderScrolled;
    },
    isHome: function() {
      if(!this.$route.name) {
        return false;
      }
      return this.$route.name.toLocaleLowerCase() === "home";
    }
  },
  created: function () {
    storyblok.get("cdn/stories", {})
        .then(response => {
          this.updateRouter(response.data.stories);
          this.$store.commit("setStoryblokData", response.data);
          console.dir(response.data.stories);
        });
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data: function() {
    return {
      isHeaderScrolled: false
    };
  }
}
</script>

<style>
@import 'assets/styles/folio.css';

#app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#main {
  flex-grow: 1;
}
</style>
