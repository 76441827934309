<template>
  <p class="reference">
    <span class="quote">"{{blok.Quote}}" - </span>
    <span class="name">{{blok.Name}}</span>
  </p>
</template>

<script>
export default {
  name: "Reference",
  props: ["blok"],
}
</script>

<style scoped>
  .reference {
    text-align: center;
  }

  .quote {
    font-size: 14px;
  }

  .name {
    font-weight: bold;
  }
</style>
