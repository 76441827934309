import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'swiper/swiper-bundle.css';
import 'vue-toastr-2/dist/vue-toastr-2.min.css';

import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import VueTypedJs from 'vue-typed-js';
import BootstrapVue from "bootstrap-vue";
import { VueMasonryPlugin } from "vue-masonry";

import Page from '@/components/Page.vue';
import Teaser from '@/components/Teaser.vue';
import Grid from '@/components/Grid.vue';
import Feature from '@/components/Feature.vue';
import Hero from '@/components/Hero.vue';
import Profile from '@/components/Profile';
import Footer from '@/components/Footer';
import Services from "@/components/Services";
import ArtAsset from "@/components/ArtAsset";
import Portfolio from "@/components/Portfolio";
import BlogTeaser from "@/components/BlogTeaser";
import Blogpost from "@/components/Blogpost";
import RichTextContent from "@/components/RichTextContent";
import CommissionColumn from "@/components/CommissionColumn";
import CommissionGrid from "@/components/CommissionGrid";
import ReferencesGrid from "@/components/ReferencesGrid";
import Reference from "@/components/Reference";
import VueToastr2 from 'vue-toastr-2';


import { Swiper as SwiperClass, Pagination, Navigation, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))

Vue.component('blok-page', Page);
Vue.component('blok-teaser', Teaser);
Vue.component('blok-grid', Grid);
Vue.component('blok-feature', Feature);
Vue.component('blok-hero', Hero);
Vue.component('blok-profile', Profile);
Vue.component('custom-footer', Footer);
Vue.component('blok-services', Services);
Vue.component('blok-art-asset', ArtAsset);
Vue.component('blok-portfolio', Portfolio);
Vue.component('blok-blog-teaser', BlogTeaser);
Vue.component('blok-blogpost', Blogpost);
Vue.component("blok-rich-text-content", RichTextContent);
Vue.component("blok-commission-grid", CommissionGrid);
Vue.component("blok-commission-column", CommissionColumn);
Vue.component("blok-reference-grid", ReferencesGrid);
Vue.component("blok-reference", Reference);

Vue.config.productionTip = false;

window.toastr = require('toastr');

Vue.use(VueMasonryPlugin);
Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(VueTypedJs);
Vue.use(VueToastr2);

const store = new Vuex.Store({
    state: {
        storyblokData: null,
        selectedLabel: 'ALL'
    },
    mutations: {
        setStoryblokData(state, payload) {
            state.storyblokData = payload;
        },
        selectLabel(state, payload) {
            state.selectedLabel = payload.toUpperCase();
        }
    },
});

Vue.filter('resize', (image, size) => {
    if (typeof image !== 'undefined') {
        return '//img2.storyblok.com/' + size + image.replace('//a.storyblok.com', '')
    }
    return null;
})

Vue.filter('dashify', function (value) {
    if (typeof value === 'undefined') {
        return 'undefined'
    }
    let dashified = value.toString()
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .toLowerCase()
        .replace(/[ _]/g, '-')

    return 'blok-' + dashified;
})

new Vue({
    render: h => h(App),
    router,
    store: store
}).$mount('#app');
