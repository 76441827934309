<template>
  <section id="contact-form" class="contact-form-container">
    <div class="container">

      <div class="row">

        <div class="col-lg-12">

          <div class="form-group">
            <label for="name-input">Name (required)</label>
            <input type="text" class="form-control" id="name-input" placeholder="Enter name" v-model="name">
          </div>

          <div class="form-group">
            <label for="email-input">Email address (required)</label>
            <small id="emailHelp" class="form-text text-muted">  We'll never share your email with anyone else.</small>
            <input type="email" class="form-control" id="email-input" aria-describedby="emailHelp" placeholder="Enter email" v-model="email">
          </div>

          <div class="form-group">
            <label for="message-text-area">Subject (required)</label>
            <input type="text" class="form-control" id="subject" placeholder="Enter subject" v-model="subject">
          </div>

          <div class="form-group">
            <label for="message-text-area">Message (required)</label>
            <textarea class="form-control textarea" id="message-text-area" v-model="message"></textarea>
          </div>

          <div class="form-group">
            <label for="how-did-you-find-me-select">How did you find me?</label>
            <select id="how-did-you-find-me-select" class="form-control selection" v-model="findOption">
              <option>Internet</option>
              <option>Friends</option>
              <option>Met you in person</option>
              <option>Other</option>
              <option>Don't wanna say</option>
            </select>
          </div>

          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="newsletter-checkbox" v-model="newsletter">
            <label class="form-check-label" for="newsletter-checkbox">I want to receive regular updates by email</label>
          </div>

          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="gdpr-checkbox" v-model="gdpr">
            <label class="form-check-label" for="gdpr-checkbox">I agree that my data entered above may be processed by email for the purpose of response emails and newsletters (only if newsletter option is selected). The data will be used, processed and stored in strictest confidence and in accordance with European data protection regulations. I can revoke this consent at any time by using this contact form again or replying to received emails from Bauxxi. The revocation of consent does not affect the lawfulness of the processing up to the time of revocation.</label>
          </div>

          <button id="submit-btn" type="submit" class="btn btn-primary" :disabled="!formValid" @click="submitForm">Create Mail</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Contact",
  props: ['subject'],
  data: function() {
    return {
      name: null,
      email: null,
      message: null,
      findOption: "Internet",
      newsletter: false,
      gdpr: false,
    }
  },
  mounted() {
    console.dir(this.subject);
  },
  computed: {
    formValid: function() {
      return this.name != null && this.name.length > 0
      && this.email != null && this.email.length > 0
      && this.subject != null && this.subject.length > 0
      && this.message != null && this.message.length > 0
      && this.gdpr;
    },
    formData: function() {
      return {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
        findOption: this.findOption,
        newsletter: this.newsletter,
        gdpr: this.gdpr,
      }
    }
  },
  methods: {
    submitForm: function() {
      if (!this.formValid) {
        return;
      }

      let body = this.message + "\n\n"
          + "Name: " + this.name + "\n"
          + "E-Mail: " + this.email + "\n"
          + "Find Option: " + this.findOption + "\n"
          + "Newsletter: " + (this.newsletter ? "Yes" : "No") + "\n"
          + "GDPR Accepted: " + (this.gdpr ? "Yes" : "No") + "\n";

      window.location.href = "mailto:bauxxi.illustrations@gmail.com?subject=" + encodeURI(this.subject)
          + "&body=" + encodeURI(body);

      this.resetInput();
    },
    resetInput: function() {
      this.subject = null;
      this.message = null;
    }
  }
}
</script>

<style scoped>
#contact-form {
  margin-top: 7em;
  margin-bottom: 10em;
}

.textarea {
  height: 12em;
}

#submit-btn {
  margin-top: 3em;
}
</style>
