<template>
  <div class="commission-column">
    <h2>{{blok.Title}}</h2>
    <div class="content">
      <img class="commission-image" :src="blok.Image.filename" :alt="blok.Image.alt">
      <p>{{blok.Text}}</p>
    </div>
    <p class="price-info"><span class="price-prefix">from</span> € {{blok.Price}}</p>
    <p><button id="submit-button" class="btn btn-secondary" @click="openMailLink('Commission: ' + blok.Title)">Contact Me at bauxxi.illustrations@gmail.com</button></p>
  </div>
</template>

<script>
export default {
  name: "CommissionColumn",
  props: ["blok"],
  methods: {
    openMailLink: function(subject) {
      window.location.href = "mailto:bauxxi.illustrations@gmail.com?subject=" + encodeURI(subject);
    }
  }
}
</script>

<style scoped>
  p {
    text-align: center;
  }

  .commission-column {
    border: 1px solid lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;
  }

  @media (max-width: 720px) {
    .commission-column {
      border: none;
      margin: 0;
    }
  }

  .commission-image {
    max-width: 100%;
    height: auto;
  }

  h2 {
    font-size: 25px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #999999;
    text-align: center;
    margin-bottom: 1em;
    margin-top: 0;
  }

  .content {
    margin-bottom: 2em;
  }

  .content > p {
    text-align: center;
    white-space: pre-line;
  }

  .price-info {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin-top: auto;
    color: #c9a172;
  }

  .price-prefix {
    font-size: 18px;
  }
</style>
