<template>
  <section class="references-grid container">
    <div class="title row">
      <div class="col-md-12">{{blok.Title}}</div>
    </div>
    <div class="row" v-for="ref in blok.References" :key="JSON.stringify(ref)">
      <Reference class="col-md-12 reference" :blok="ref"></Reference>
    </div>
  </section>
</template>

<script>
import Reference from "@/components/Reference";

export default {
  name: "ReferencesGrid",
  components: {Reference},
  props: ["blok"],
}
</script>

<style scoped>
  .references-grid {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  .reference {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .title {
    font-size: 25px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #999999;
    text-align: center;
    margin-bottom: 1em;
  }
</style>
