<template>
  <div
    :v-editable="blok"
    id="hero"
    class="home"
    :style="
      'background: url(' +
      blok.image.filename +
      ') repeat scroll center center/cover'
    "
  >
    <div class="container">
      <div class="hero-content">
        <h1>I'm <span class="typed" :data-typed-items="iAmItems"></span></h1>
        <p>{{ blok.descriptions }}</p>

        <ul class="list-unstyled list-social">
          <li>
            <a :href="blok.facebook.url" target="_blank">
              <i class="bi bi-facebook"></i>
            </a>
          </li>
          <li>
            <a :href="blok.twitter.url" target="_blank">
              <i class="bi bi-twitter"></i>
            </a>
          </li>
          <li>
            <a :href="blok.instagram.url" target="_blank">
              <i class="bi bi-instagram"></i
            ></a>
          </li>
          <li>
            <a :href="blok.twitch.url" target="_blank">
              <i class="bi bi-twitch"></i>
            </a>
          </li>
          <li>
            <a :href="blok.patreon.url" target="_blank">
              <i class="bi bi-file-ppt"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Typed from "typed.js";

export default {
  name: "Hero",
  props: ["blok"],
  mounted: function () {
    let typed_strings = this.iAmItems;
    typed_strings = typed_strings.split(",");
    new Typed(".typed", {
      strings: typed_strings,
      loop: true,
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2000,
    });
  },
  computed: {
    iAmItems: function () {
      const descriptions = [];
      const vowelRegex = "^[aieouAIEOU].*";
      this.blok.descriptions.split(",").forEach((d) => {
        const matched = d.trim().match(vowelRegex);
        if (matched) {
          descriptions.push("an " + d.trim());
        } else {
          descriptions.push("a " + d.trim());
        }
      });

      return this.blok.name + ", " + descriptions.join(",");
    },
  },
};
</script>

<style>
.list-unstyled {
  list-style-type: none;
}
</style>
