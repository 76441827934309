<template>
  <div :v-editable="blok" class="column feature">
    {{ blok.name }}
  </div>
</template>

<script>
export default {
  name: "Feature",
  props: ['blok']
}
</script>
