<template>
  <div id="services">
    <div class="container">
      <div class="services-slider swiper-container">
        <swiper class="swiper" :options="swiperOptions">
          <swiper-slide class="swiper-slide" v-for="service in blok.services" :key="JSON.stringify(service)">
            <div v-if="!service.url || !service.url.cached_url" class="services-block">
              <i :class="'bi bi-' + service.icon"></i>
              <span>{{service.name}}</span>
              <p class="separator">{{service.text}}</p>
            </div>
            <a class="services-block" v-if="service.url && service.url.cached_url" :href="service.url.cached_url" target="_blank">
              <i :class="'bi bi-' + service.icon"></i>
              <span>{{service.name}}</span>
              <p class="separator">{{service.text}}</p>
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>

import {Swiper, SwiperSlide} from "vue-awesome-swiper";

export default {
  name: "Services",
  components: {
    Swiper,
    SwiperSlide
  },
  props: ["blok"],
  computed: {
    swiper: function() {
      return this.$refs.serviceSwiper.$swiper
    },
  },
  data() {
    return {
      swiperOptions: {
        speed: 600,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }
      }
    }
  },
}
</script>
