<template>
  <section v-if="blogs && blogs.length > 0" class="journal blog-teaser-container">
    <div class="container">
      <div class="section-title text-center">
        <h2>{{blok.title}}</h2>
      </div>
    </div>
    <div class="container">
      <div class="journal-block">
        <div class="row">
          <div class="col-lg-4 col-md-6" v-for="blog in blogs" :key="blog._uid">
            <a href="#" class="journal-info" @click="goToDetailPage(blog)">

              <img :src="blog.content.image.filename" class="img-responsive" alt="img">
              <div class="journal-txt">
                <h4>{{blog.content.title}}</h4>
                <p class="separator">{{blog.content.shortText}}</p>
              </div>

            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlogTeaser",
  props: ["blok"],
  mounted() {
    const blogposts = [];
    this.$store.state.storyblokData.stories.forEach(s => {
      if(s.content.component === "blogpost") {
        blogposts.push(s);
      }
    })
    blogposts.sort((a, b) => a.first_published_at.localeCompare(b));
    this.blogs = blogposts.slice(0, 3);
  },
  data: function() {
    return {
      blogs: []
    }
  },
  methods: {
    goToDetailPage: function(blog) {
      this.$router.push(blog.full_slug);
    }
  }
}
</script>

<style scoped>
.blog-teaser-container {
  padding-top: 1em;
}
.journal {
  background-color: #e2d4c3;
}
</style>
