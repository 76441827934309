<template>
  <div class="rich-text-content container">
    <div class="row">
      <div class="col-md-12" v-html="richtext"></div>
    </div>
  </div>
</template>

<script>
import storyblok from '@/utils/storyblok.js';

export default {
  name: "RichTextContent",
  props: ["blok"],
  computed: {
    richtext() {
      return this.blok.text ? storyblok.richTextResolver.render(this.blok.text) : ''
    }
  },
}
</script>

<style scoped>
  .rich-text-content {
    overflow-wrap: break-word;
  }

  @media (max-width: 720px) {
    .rich-text-content {
      margin-left: 0;
      margin-right: 0;
    }
  }
</style>
