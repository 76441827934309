<template>
  <div :v-editable="blok" class="portfolio paddsection">
    <div class="container">
      <div class="section-title text-center">
        <h2>{{blok.title}}</h2>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-filters">
            <li @click="setLabel('all')" :class="{active: isLabelActive('ALL')}">All</li>
            <li v-for="tag in tags" :key="tag" @click="setLabel(tag)" :class="{active: isLabelActive(tag)}">{{tag}}</li>
          </ul>
        </div>
      </div>
      <div class="row portfolio-container">
        <component :key="blok._uid" v-for="blok in blok.assets" :blok="blok" :is="blok.component | dashify"></component>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "Portfolio",
  props: ["blok"],
  computed: {
    tags: function() {
      console.dir(this.blok.assets[0].columns);
      const uniqueLabels = [];
      this.blok.assets[0].columns.forEach(c => {
        const labels = c.labels.split(" ");
        labels.forEach(lbl => {
          if(uniqueLabels.indexOf(lbl.toUpperCase()) === -1) uniqueLabels.push(lbl.toUpperCase());
        });
      });
      return uniqueLabels;
    }
  },
  methods: {
    setLabel: function(label) {
      this.$store.commit("selectLabel", label);
    },
    isLabelActive: function(label) {
      return this.$store.state.selectedLabel === label.toUpperCase();
    }
  }
}
</script>

<style scoped>
.portfolio {
  padding-top: 6em;
  background-color: #222;
}

#portfolio-filters > li.active{
  color: #c9a172;
}

.section-title > h2 {
  color: #c9a172;
}
</style>
