<template>
  <div v-if="hasLabel"
       :v-editable="blok"
       class="item art-asset col-md-3"
       v-masonry-tile
       @click="goToDetailPage"
       @mouseenter="toggleShowInfo(true)"
       @mouseleave="toggleShowInfo(false)">
    <img :src="blok.asset.filename" class="img-fluid uniform-image" alt="">
    <div class="portfolio-info" :class="{'display-info': showInfo}">
      <h4>{{blok.title}}</h4>
      <p>{{blok.description}}</p>
    </div>
  </div>

</template>

<script>
export default {
  name: "ArtAsset",
  props: ['blok'],
  methods: {
    toggleShowInfo: function(show) {
      this.showInfo = show;
    },
    goToDetailPage: function() {
      this.$router.push({path: 'artAssetDetail', query: { id: this.blok._uid }})
    }
  },
  computed: {
    hasLabel: function() {
      if(this.$store.state.selectedLabel.toUpperCase() === "ALL") {
        return true;
      }

      if(!this.blok.labels || this.blok.labels.length === 0) {
        return false;
      }

      let result = false;
      this.blok.labels.split(" ").forEach(lbl => {
        result = result || (this.$store.state.selectedLabel === lbl.toUpperCase());
      });

      return result;
    }
  },
  data() {
    return {
      showInfo: false,
    };
  }
}
</script>

<style scoped>
.item {
  margin: 1em;
}

.portfolio-info {
  display:none;
  height: 6em;
  overflow: hidden;
  position:absolute;
  margin-top: -6em;
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}

.portfolio-info h4 {
  color: #eeeeee;
}

.portfolio-info p {
  color: #eeeeee;
}

.display-info {
  display: block !important;
}
</style>
